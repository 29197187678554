<template>
  <div class="about">
    <el-table :data="tableData" @row-click="rows" height="calc(100% - 40px)">
      <el-table-column v-for="item in tableArr" :key="item.index" :prop="item.prop" :label="item.label" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" width="190">
        <template slot-scope="{ row }">
          <el-button v-if="$has(cdivice[1])" @click="lookDialog(row)" type="primary" size="small" icon="el-icon-edit-outline">编 辑</el-button>
        </template>
      </el-table-column>
      <div slot="empty" class="empty">
        <img src="../../../public/home/noData.svg" width="320" alt="" />
        <span>暂无数据</span>
      </div>
    </el-table>
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currpage" :page-sizes="[10, 20, 100, 500, 1000, 5000, 10000]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totals"> </el-pagination>
    <ToTop />
    <!-- 编辑模态框 -->
    <el-dialog title="编辑阈值管理" :visible.sync="modifyCenterDialogVisible" width="30%" :modal="true" :close-on-click-modal="false" v-dialogDrag center>
      <div class="dialogsC">
        <div class="newDialog">
          <div v-for="i in tableArr" :key="i.index">
            <span>{{ i.label }}</span
            >：
            <el-input v-model="paramObj[i.prop]" clearable :disabled="i.disabled"></el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="modifyCenterDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="modifys">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import qs from 'qs'
import { queryThreshold, updateThreshold } from '@/util/http'
import ToTop from '@/components/toTop/toTop.vue'
export default {
  components: { ToTop },
  data() {
    return {
      currpage: 1,
      pagesize: 10,
      totals: 0,
      tableData: [], //数据
      modifyCenterDialogVisible: false, //控制编辑模态框
      mcode: '', //编辑code值
      mcodeDesc: '', //编辑code名
      mident: '', //编辑备注
      mcodeType: '', // code类型
      tableArr: [
        { prop: 'codeDesc', label: '阈值名', disabled: true },
        { prop: 'code', label: '阈值', disabled: false },
        { prop: 'ident', label: '备注', disabled: false },
      ],
      cdivice: undefined, //设备类型
      paramObj: {}, //查看对象
    }
  },
  props: ['datas'],
  watch: {
    datas: {
      handler(newValue, oldValue) {
        this.cdivice = newValue
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 分页
    handleSizeChange(val) {
      this.pagesize = val
      this.searchs(this.currpage, val)
    },
    handleCurrentChange(val) {
      this.currpage = val
      this.searchs(val, this.pagesize)
    },
    // 详情
    lookDialog(row) {
      this.paramObj = Object.assign({}, row)
      this.modifyCenterDialogVisible = true
    },
    // 获取当前行的所有信息
    rows(row) {
      this.mcodeType = row.codeType
    },
    // 查询
    async searchs(currpage, pagesize) {
      const loading = this.$loading({
        lock: true,
        text: '拼命加载中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
      })
      this.currpage = currpage
      let params = {
        pageNo: currpage,
        pageSize: pagesize,
        deviceType: this.cdivice[0],
      }
      await queryThreshold(params)
        .then((data) => {
          if (data) {
            this.tableData = data.result.records
            this.totals = data.result.total
          }
        })
        .catch(() => {})
      loading.close()
    },
    // 修改
    modifys() {
      // 只能输入正整数和0
      if (this.paramObj.codeType == 'nuclide_overtime' && !/^\d+$/.test(this.paramObj.code)) {
        this.$message.error('请输入非负整数')
        return
      }
      // 只能输入大于等于5的正整数
      let ts = /^\d+$/.test(this.paramObj.code) && parseInt(this.paramObj.code) > 4
      if (this.paramObj.codeType == 'nuclide_measure_time' && !ts) {
        this.$message.error('请输入大于等于5的整数')
        return
      }
      // 正数字
      if (this.paramObj.codeType == 'nuclide_discharge_criteria' && !/^\d*\.?\d+$/.test(this.paramObj.code)) {
        this.$message.error('请输入正数字')
        return
      }
      this.paramObj.deviceType = this.cdivice[0]
      updateThreshold(qs.stringify(this.paramObj)).then((data) => {
        if (data) {
          this.$message.success(data.message)
          this.searchs(this.currpage, this.pagesize)
          this.modifyCenterDialogVisible = false
        }
      })
    },
  },
  mounted() {
    this.searchs(1, 10)
  },
}
</script>
<style src="../../../public/css/search.less" lang="less" scoped></style>
<style lang="less" scoped></style>
